import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../Home/TopHeader";
import SocialMediaSideBar from "./SocialMediaSideBar";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import {
  postWithTokenWithData,
  postWithTokenWithDataReturnDate,
} from "../../utility/apiRequest"; // Adjust the import path accordingly
import DonorManagementTop from "../DonorManagement/DonorManagementTop";
import { TQ_URL } from "../../utility/baseUrl";
import axios from "axios";
import SocialMediaTour from "./SocialMediaTour";

const FB_APP_ID = "830389442285126";
const FB_SCOPE =
  "business_management,pages_manage_cta,pages_show_list,pages_messaging,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement";
const SCOPES =
  "business_management,instagram_basic,instagram_manage_insights,instagram_content_publish,instagram_shopping_tag_products,instagram_manage_events";
const THREADS_APP_ID = "1716315319205951"; // Your Threads App ID
const THREADS_REDIRECT_URI = "https://p3dl96c.localto.net/threads-auth"; // Redirect after login

const LINKEDIN_CLIENT_ID = '77eoxsx25of9bt'; // Your LinkedIn Client ID
const LINKEDIN_REDIRECT_URI = 'https://9d29-117-195-18-164.ngrok-free.app/oauth/callback'; // Your backend callback URL
const LINKEDIN_STATE = 'random_state_string'; // Add a random unique value to prevent CSRF attacks
const LINKEDIN_SCOPE = 'openid profile email w_member_social rw_organization_admin'; // Add rw_organization_admin

// LinkedIn Authorization URL
const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(LINKEDIN_REDIRECT_URI)}&state=${LINKEDIN_STATE}&scope=${encodeURIComponent(LINKEDIN_SCOPE)}`;



const SocialMediaCampaign = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingThreads, setLoadingThreads] = useState(false);
  const [accessToken, setAccessToken] = useState(
    location.state?.accessToken || ""
  );
  const [instagramAccessToken, setInstagramAccessToken] = useState("");
  const [error, setError] = useState(null);
  const [loadingFB, setLoadingFB] = useState(false);
  const [loadingInstagram, setLoadingInstagram] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [longLivedToken, setLongLivedToken] = useState(null);
  const [pageId, setPageId] = useState(null);
  // const [tourCompletedFlag, setTourCompletedFlag] = useState(null);

  const tourCompletedFlag = localStorage.getItem("socialTourCompletedFlag");

  const partnerData = JSON.parse(localStorage.getItem("partnerData"));

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  // This useEffect hook will handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    // Call the resize handler initially to set the correct value
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to initiate OAuth flow and redirect after login
  const handleThreadsLogin = () => {
    const authUrl = `https://threads.net/oauth/authorize?client_id=${THREADS_APP_ID}&redirect_uri=${encodeURIComponent(THREADS_REDIRECT_URI)}&scope=threads_basic,threads_content_publish&response_type=code`;

    // Redirect to the Threads authorization page
    window.location.href = authUrl;
  };

  const handleLinkedInLogin = () => {
    window.location.href = linkedInAuthURL;
  };


  const handleOAuthCallback = async (authorizationCode) => {
    try {
      // Send the authorization code (short-lived token) to the backend
      const response = await axios.post("/api/threads/exchange-token", {
        code: authorizationCode,
      });

      // Extract long-lived token and page information from backend response
      const { longLivedToken, pageId } = response.data;

      // Store long-lived token and pageId in state
      setLongLivedToken(longLivedToken);
      setPageId(pageId);
    } catch (err) {
      setError(err.message || "Error exchanging token");
    } finally {
      setLoadingThreads(false);
    }
  };


  // useEffect(() => {
  //   const fetchTourStatus = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${TQ_URL}/user-tour-status/${partnerId}/${moduleId}`
  //       );
  //       console.log(response.data.tourCompleted);

  //       setTourCompletedFlag(response.data.tourCompleted); // Assuming your API returns the status in 'tourCompleted'
  //     } catch (error) {
  //       console.error("Error fetching tour status:", error);
  //     } finally {
  //       setIsTableLoading(false); // Set loading to false after fetching the flag
  //     }
  //   };
  //   fetchTourStatus();
  // }, []);

  useEffect(() => {
    // Load the Facebook SDK for JavaScript
    ((d, s, id) => {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    // Initialize the SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
    };
  }, []);

  const saveFacebookData = async (shortLivedToken) => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData")); // Retrieve partnerData from localStorage
    const facebookData = {
      partnerId: partnerData.partnerId, // Use partnerId from partnerData
      channel: "Facebook",
      shortLivedToken: shortLivedToken,
    };
    const endPoint = "/api/facebook/save";
    const url = `${TQ_URL}${endPoint}`;
    try {
      const response = await postWithTokenWithDataReturnDate(url, facebookData);
      if (response.status === 201) {
        navigate("/connectedChannels");
      } else if (response === "Data saved successfully") {
        setModalMessage(response);
        setIsModalOpen(true);
        setIsModalOpen(false);
        navigate("/connectedChannels");
      } else {
        setModalMessage(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error saving data to backend:", error);
      setModalMessage(
        error.response?.data || error.message || "Error saving data to backend"
      );
      setIsModalOpen(true);
    } finally {
      setLoadingFB(false);
    }
  };

  const saveInstagramData = async (shortLivedToken) => {
    const partnerData = JSON.parse(localStorage.getItem("partnerData")); // Retrieve partnerData from localStorage
    const facebookData = {
      partnerId: partnerData.partnerId, // Use partnerId from partnerData
      channel: "Instagram",
      shortLivedToken: shortLivedToken,
    };
    const endPoint = "/api/facebook/save";
    const url = `${TQ_URL}${endPoint}`;
    try {
      const response = await postWithTokenWithData(url, facebookData);
      if (response.status === 201) {
        navigate("/connectedChannels");
      } else if (response === "Data saved successfully") {
        setModalMessage(response);
        setIsModalOpen(true);
        setIsModalOpen(false);
        navigate("/connectedChannels");
      } else {
        setModalMessage(response.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error saving data to backend:", error);
      setModalMessage(
        error.response?.data || error.message || "Error saving data to backend"
      );
      setIsModalOpen(true);
    } finally {
      setLoadingInstagram(false);
    }
  };

  const handleFBLogin = () => {
    setLoadingFB(true);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log("Welcome! Fetching your information.... ");
          setAccessToken(response.authResponse.accessToken);
          saveFacebookData(response.authResponse.accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          setLoadingFB(false);
          setModalMessage("Failed to authenticate with Facebook");
          setIsModalOpen(true);
        }
      },
      { scope: FB_SCOPE }
    );
  };

  const handleInstagramLogin = () => {
    setLoadingInstagram(true);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log(
            "Instagram Access Token:",
            response.authResponse.accessToken
          );
          setInstagramAccessToken(response.authResponse.accessToken);
          saveInstagramData(response.authResponse.accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          setLoadingInstagram(false);
          setModalMessage("Failed to authenticate with Instagram");
          setIsModalOpen(true);
        }
      },
      { scope: SCOPES }
    );
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-10">
        <DonorManagementTop />
      </div>
      <div className="flex mt-[50px]">
        <div className="md:w-64 w-1/12 absolute md:fixed">
          <SocialMediaSideBar />
        </div>
        <main className="w-full verify-user md:ml-[250px] md:pb-[100px]">
          <div className="mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6">
              <div className="text-center">
                <h2 className="text-2xl font-bold">Social Media Campaign</h2>
              </div>
            </div>
          </div>
          <div className="mx-auto h-full pt-10 overflow-hidden">
            <div className="flex flex-col justify-between items-center">
              <div className="bx-container w-full lg:w-3/6 p-4 shadow-lg bg-white">
                <div className="flex flex-row container justify-between">
                  <div className="flex flex-row items-center">
                    <FaFacebook className="mr-2 text-6xl text-[#1877f2] max-sm:text-4xl" />
                    <div className="font-bold text-2xl mb-2 ml-5 mr-2 max-sm:text-xl">
                      Facebook <span className="text-sm block">Page</span>
                    </div>
                  </div>
                  <button
                    id="connect_button"
                    className="btn-style-1 text-white text-lg rounded"
                    onClick={handleFBLogin}
                    disabled={loadingFB}
                    style={{ width: "120px" }}
                  >
                    {loadingFB ? "Connecting..." : "Connect"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center mt-7">
              <div className="bx-container w-full lg:w-3/6 p-4 shadow-lg bg-white">
                <div className="flex flex-row container justify-between">
                  <div className="flex flex-row items-center">
                    <FaInstagramSquare className="mr-2 text-6xl text-[#f04d48] max-sm:text-4xl" />
                    <div className="font-bold text-2xl mb-2 ml-5 mr-2 max-sm:text-xl">
                      Instagram{" "}
                      <span className="text-sm block">Business accounts</span>
                    </div>
                  </div>
                  <button
                    className="btn-style-1 text-white text-lg rounded"
                    onClick={handleInstagramLogin}
                    disabled={loadingInstagram}
                    style={{ width: "120px" }}
                  >
                    {loadingInstagram ? "Connecting..." : "Connect"}
                  </button>
                </div>
              </div>
            </div>
            {/* <div>
              <button
                className="btn-style-1 text-white text-lg rounded"
                onClick={handleThreadsLogin}
                disabled={loadingThreads}
                style={{ width: "120px" }}
              >
                {loadingThreads ? "Connecting..." : "Connect Threads"}
              </button>
            </div>
            <div>
              <h1>Login with LinkedIn</h1>
              <button onClick={handleLinkedInLogin}>Login via LinkedIn</button>
            </div> */}
            {error && (
              <div>
                <h1>Error</h1>
                <p>
                  {typeof error === "string" ? error : JSON.stringify(error)}
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Error</h2>
            <p>{modalMessage}</p>
            <button
              className="btn-style-1 text-white text-lg rounded px-10 mt-4"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          .bx-container {
            width: 90%;
          }
          .btn-style-1 {
            padding: 10px 20px;
          }
        }
      `}</style>

      {tourCompletedFlag === "false" && isLargeScreen && <SocialMediaTour />}
    </>
  );
};

export default SocialMediaCampaign;
